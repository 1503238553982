import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { TimerComponent } from '@unleashed/booking/components';
import { BookingSteps, NavigationSteps } from '@unleashed/models/booking';
import { AuthorizationService } from '@unleashed/services/account';
import { BookingSessionService } from '@unleashed/services/booking';
import { Router } from '@angular/router';

@Component({
  selector: 'ua-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {

  @ViewChild(TimerComponent) private timerComponent?: TimerComponent;
  cartCookieName: string;
  bookingCookieName: string;
  navSet = false;

  constructor(
    private route: ActivatedRoute,
    public sessionService: BookingSessionService,
    public authorizationService: AuthorizationService,
    private cookieService: CookieService,
    private router: Router,
    @Inject(APP_OPTIONS) appOptions: AppOptions
  ) {
    this.cartCookieName = appOptions.cookieName;
    this.bookingCookieName = appOptions.bookingCookieName;
  }
  ngOnInit(): void {
    const cartId =  this.route.snapshot.queryParamMap.get('cartId') as string | undefined;
    const accessToken =  this.route.snapshot.queryParamMap.get('token') as string | undefined;
    const hideSteps = this.route.snapshot.queryParamMap.get('hideSteps') as string | undefined;
    this.setQueryParams(hideSteps === 'true');
    if (cartId) {
      this.sessionService.initializeBooking(cartId, 7)
        .subscribe(b => {
          this.sessionService.navigateToStep(NavigationSteps.Review);
        }, err => {
          if (err.error.failureCode === 'CART1004') {
            this.navSet = true;
            this.sessionService.navigateToStep(NavigationSteps.NotFound);
          }
        });
    }
    if (accessToken) {
      this.sessionService.removeCart();
      this.authorizationService.impersonateUser(accessToken);
    }

    const slug = this.route.snapshot.paramMap.get('slug') as string | undefined;
    this.sessionService.setParkBySlug(slug);

    this.sessionService.getBooking()
      .subscribe(booking => {
        if (this.navSet) {
          return;
        }
        const cookie = this.cookieService.get(this.cartCookieName);
        const route = this.route.children ? this.route.children[0].routeConfig?.path : '';
        if (booking.cookieId && cookie) {
          const step = BookingSteps[booking.step ?? 0];
          this.navSet = true;
          if (step !== route) {
            switch (booking.step) {
              case 1: {
                this.sessionService.navigateToStep(NavigationSteps.Packages);
                break;
              }
              case 2: {
                this.sessionService.navigateToStep(NavigationSteps.TimeSlots);
                break;
              }
              case 3: {
                this.sessionService.navigateToStep(NavigationSteps.Hangouts);
                break;
              }
              case 4: {
                this.sessionService.navigateToStep(NavigationSteps.Attendees);
                break;
              }
              case 5: {
                this.sessionService.navigateToStep(NavigationSteps.AddOns);
                break;
              }
              case 6: {
                this.sessionService.navigateToStep(NavigationSteps.Review);
                break;
              }
              case 7: {
                this.sessionService.navigateToStep(NavigationSteps.Checkout);
                break;
              }
              default: {
                this.sessionService.navigateToStep(NavigationSteps.Start);
                break;
              }
            }
          }
        } else {
          if (route !== NavigationSteps.Start && route !== NavigationSteps.NotFound) {
            this.sessionService.navigateToStep(NavigationSteps.Start);
          }

          if (!cookie) {
            this.navSet = true;
          }

          let bookingId = this.route.snapshot.queryParamMap.get('bookingId') as string | undefined;
          bookingId ??=  this.cookieService.get(this.bookingCookieName);
          if (bookingId) {
            this.sessionService.setBookingCookie(bookingId);
            this.sessionService.navigateToStep(NavigationSteps.Confirmation);
          }
        }
      });
  }

  goToStart(): void {
    this.timerComponent?.goToStart();
  }

  extendTime(): void {
    this.timerComponent?.extendTime();
  }

  setQueryParams(hideSteps: boolean = false): void {
     if (this.router.url.includes('start')) {
       localStorage.setItem('bookingQueries', JSON.stringify(this.route.snapshot.queryParamMap));
     } else if (hideSteps) {
       localStorage.setItem('bookingQueries', '{"params":{"hideSteps":"true"}}');
     }
  }
}
