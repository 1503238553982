import { Component, AfterViewChecked } from '@angular/core';
declare function insertProgressBar(): void;
@Component({
  selector: 'ua-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked {
  ngAfterViewChecked(): void {
    insertProgressBar();
  }
}
