<ng-container *ngIf="invoice$ | async as invoice; else skeleton">
  <div class="mt-4">
    <ua-invoice [invoice]="invoice" [booking]="booking"></ua-invoice>
  </div>

  <hr class="my-2 bg-primary">

  <div class="mb-3" *ngIf="!invoice.isSpecialEvent && !(user?.impersonated ?? false)">
    <label for="promoCode">Promo Code</label>
    <div class="d-flex justify-content-between input-group">
      <input id="promoCode"
             type="text"
             class="form-control flex-grow-1"
             placeholder="Enter Promo Code"
             [(ngModel)]="promoCode"
             [disabled]="promoApplied"
             maxlength="32">
      <button class="btn"
              [ngClass]="{'btn-primary': !promoApplied, 'btn-secondary': promoApplied}"
              (click)="confirmAlterPromo()"
      >{{promoApplied ? "Remove" : "Apply"}}</button>
    </div>
    <div class="text-success" *ngIf="promoApplied">
      Promo Code Applied!
    </div>
    <div class="text-danger" *ngIf="invalidPromoCode">
      Sorry, the promo code <span class="fst-italic">{{invalidPromoCode}}</span> is invalid for this booking
    </div>
  </div>

  <div class="d-flex flex-row text-center">
    <h5 class="fw-bold">
      A payment of <ua-display-price [amount]="invoice.deposit"></ua-display-price> is required today to complete your order.
    </h5>
  </div>

  <div *ngIf="currencyCode === 'CAD' && paymentProcessorVersion === 1" class="fst-italic">
    Please note that the price displayed on our website is in Canadian Dollars (CAD), but you will be charged in US
    Dollars (USD) at the current exchange rate.
  </div>
  <ua-location-confirmation (confirmLocationEvent)="handleLocationConfirmation($event)"
                            (restartEvent)="handleRestart()"
                            [invoice]="invoice"
                            [triggerLocationPopup]="triggerLocationPopup">
  </ua-location-confirmation>


</ng-container>

<div class="mt-5">
  <button class="btn btn-primary btn-lg w-100 fw-bold" [ngClass]="user?.impersonated ? 'mb-4' : ''"
          (click)="showLocationConfirmation()"
          [disabled]="!detailLoaded">
    {{ isSpecialEvent ? 'Continue' : 'Reserve Party'}}
  </button>
</div>

<ng-container *ngIf="!isSpecialEvent && !(user?.impersonated ?? false)">
  <div class="mt-4">
    <button class="btn btn-outline-primary btn-lg w-100 fw-bold" (click)="back()">Go Back</button>
  </div>

  <div class="mt-4">
    <ua-start-over></ua-start-over>
  </div>
</ng-container>

<ng-template #skeleton>
  <ua-invoice-skeleton></ua-invoice-skeleton>
</ng-template>
<ua-promo-confirmation [triggerPromoConfirmationPopup]="triggerPromoConfirmationPopup"
                       (confirmPromo)="handlePromoConfirmation()"
                       (cancelPromo)="handlePromoCancel()"
></ua-promo-confirmation>
