function insertProgressBar() {
  const currentPath = window.location.pathname;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const hideSteps = urlParams.get('hideSteps') === 'true';
  if (hideSteps) { return; }

  const urlToNumberMap = {
    '/start': 1,
    '/packages': 2,
    '/timeslots': 3,
    '/hangouts': 4,
    '/attendees': 5,
    '/addons': 6,
    '/review': 7,
    '/checkout': 8,
    '/confirmation': 9
  }
  const pathWithoutLocation = currentPath.replace(/\/parks\/[^\/]+/, '');
  const highlightedNumber = urlToNumberMap[pathWithoutLocation];

  if (!highlightedNumber) {
    return;
  }

  const numberRow = document.createElement('div');
  numberRow.style.display = 'flex';
  numberRow.style.justifyContent = 'space-between';
  numberRow.style.alignItems = 'center';
  numberRow.style.width = '100%';
  numberRow.style.flex = '1 0 100%';
  numberRow.id = 'progress-bar';

  for (let i = 1; i <= 9; i++) {
    const numberCircle = document.createElement('div');

    if (i < highlightedNumber) {
      numberCircle.innerHTML = `<svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#E50695" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c-9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>`;
      numberCircle.style.backgroundColor = 'transparent';
      numberCircle.style.border = 'none';
    } else {
      numberCircle.textContent = i;
      numberCircle.style.backgroundColor = '#f0f0f0';
      numberCircle.style.color = 'black';
    }

    numberCircle.style.width = '40px';
    numberCircle.style.height = '40px';
    numberCircle.style.borderRadius = '50%';
    numberCircle.style.display = 'flex';
    numberCircle.style.justifyContent = 'center';
    numberCircle.style.alignItems = 'center';
    numberCircle.style.border = '1px solid #ccc';
    numberCircle.style.fontSize = '16px';
    numberCircle.style.fontWeight = 'bold';

    if (i === highlightedNumber) {
      numberCircle.style.backgroundColor = '#E50695';
      numberCircle.style.color = 'white';
    }

    numberRow.appendChild(numberCircle);
  }
  if (currentPath.endsWith('/review')) {
    const specialTarget = document.querySelector('div.sub-header.w-100.bg-white');

    if (specialTarget) {
      const existingProgressBar = document.querySelector('#progress-bar');
      if (existingProgressBar) existingProgressBar.remove();

      numberRow.style.marginTop = '4rem';
      numberRow.style.marginBottom = '-3rem';
      specialTarget.parentNode.insertBefore(numberRow, specialTarget.nextSibling);
    }
  } else {
    const targetSection = document.querySelector(".mt-4:first-of-type");
    //console.log("targetSection: " + (targetSection ? targetSection.outerHTML : "not found"));
    if (targetSection) {
      const existingProgressBar = document.querySelector('#progress-bar');
      if (existingProgressBar) existingProgressBar.remove();

      targetSection.insertBefore(numberRow, targetSection.firstChild);
    }
  }
}
